<template>
  <b-container fluid>
    <iq-card>
    <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('warehouse_config.bank-loan-calculator') }}</h4>
        </template>
      <template v-slot:body>
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(calculateData)" @reset.prevent="reset" >
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <ValidationProvider name="Crop Type" vid='cropTypeId' rules="required|min_value:1">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="cropTypeId"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                            {{ $t('warehouse_config.crop_type')}} <span class="text-danger">*</span>
                        </template>
                    <b-form-select
                        plain
                        v-model="search.cropTypeId"
                        :options="cropTypeList"
                        id="cropTypeId"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <ValidationProvider name="Crop Name" vid='cropId' rules="required|min_value:1">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="cropId"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                            {{ $t('warehouse_config.crop_name')}} <span class="text-danger">*</span>
                        </template>
                    <b-form-select
                        plain
                        v-model="search.cropId"
                        :options="cropList"
                        id="cropId"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <ValidationProvider name="Current Price" vid='current-price' rules="required">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="current-price"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('warehouse_config.current-price')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="current-price"
                            v-model="search.currentPrice"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <ValidationProvider name="Product Quantity" vid='productQuantity' rules="required">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="product_quantity"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('warehouse_config.product_quantity')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="product_quantity"
                            v-model="search.productQuantity"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <ValidationProvider name="Interest Rate" rules="required">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="interest_rate"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('warehouse_config.interest_rate')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="interest_rate"
                            v-model="search.interestRate"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6">
                    <ValidationProvider name="Month" vid='monthId' rules="required|min_value:1">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="monthId"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                            {{ $t('warehouse_config.month')}} <span class="text-danger">*</span>
                        </template>
                    <b-form-select
                        plain
                        v-model="search.monthId"
                        :options="monthList"
                        id="monthId"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12 text-right">
                    <b-button type="submit" class="mr-2" variant="primary">{{ $t('warehouse_config.calculate')}}</b-button>
                    <b-button variant="danger" @click="clearData">{{ $t('globalTrans.reset')}}</b-button>
                </b-col>
            </b-row>
        </b-form>
     </ValidationObserver>
      </template>
    </iq-card>
    <iq-card>
    <b-overlay :show="loadingState">
        <b-row v-show="loanAmount != undefined && loanAmount != ''">
            <b-col md= "2"></b-col>
            <b-col>
                <iq-card>
                <template v-slot:headerTitle>
                    <span class="text-center"><h4 class="card-title">{{ $t('warehouse_config.loanCalculationView') }}</h4></span>
                </template>
                <template v-slot:body>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_config.loanableProductQty') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ $n(totalQuantity) }} ({{ $n(loanRate) }}%)</p>
                      </b-col>
                    </b-row>
                   <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_config.totalLoanAmount') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ $n(loanAmount) }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_config.totalInterest') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ $n(interestAmount) }}</p>
                      </b-col>
                    </b-row>
                </template>
                </iq-card>
            </b-col>
            <b-col md= "2"></b-col>
        </b-row>
    </b-overlay>
    </iq-card>
    <b-row v-show="message != ''">
            <b-col md= "4"></b-col>
            <b-col>
                <br><br><h4 class="text-dark">{{ message }}</h4><br><br><br>
            </b-col>
            <b-col md= "2"></b-col>
        </b-row>
  </b-container>
</template>
<script>

import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'

export default {
  name: 'UiDataTable',
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        cropTypeId: '0',
        cropId: '0',
        monthId: '0',
        currentPrice: '',
        productQuantity: '',
        interestRate: ''
      },
    loanAmount: '',
    interestAmount: '',
    totalQuantity: '',
    loanRate: '',
    message: '',
      testId: 0,
      rows: [],
      cropList: []
    }
  },
  computed: {
    formTitle () {
       return (this.testId === 0) ? this.$t('warehouse_config.bank-loan-calculator') + ' ' + this.$t('globalTrans.entry') : this.$t('warehouse_config.bank-loan-calculator') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    cropTypeList: function () {
        return this.$store.state.warehouse.commodityGroupList
    },
    monthList: function () {
      const month = [
        { value: 1, text: this.$n(1) }, { value: 2, text: this.$n(2) }, { value: 3, text: this.$n(3) }, { value: 4, text: this.$n(4) },
        { value: 5, text: this.$n(5) }, { value: 6, text: this.$n(6) }, { value: 7, text: this.$n(7) }, { value: 8, text: this.$n(8) },
        { value: 9, text: this.$n(9) }, { value: 10, text: this.$n(10) }, { value: 11, text: this.$n(11) }, { value: 12, text: this.$n(12) }
      ]
      return month
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.cropTypeId': function (newVal, oldVal) {
      this.cropList = this.getCropList(newVal)
    }
  },
  methods: {
    calculateData () {
        if (this.search) {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            RestApi.getData(warehouseServiceBaseUrl, '/master-loan-rate/rate/' + this.search.cropTypeId).then(response => {
                if (response.success) {
                    if (response.data != null) {
                        this.message = ''
                        const rate = response.data.percentage
                        this.loanRate = rate
                        this.totalQuantity = this.search.productQuantity
                        this.loanAmount = ((((this.search.currentPrice * this.search.productQuantity) * rate) / 100) / 12) * this.search.monthId
                        this.interestAmount = (((this.loanAmount * this.search.interestRate) / 100) / 12) * this.search.monthId
                    } else {
                        this.message = this.$i18n.locale === 'en' ? 'No Data found for this crop type!' : 'কোন তথ্য পাওয়া যায় নি'
                        this.totalQuantity = ''
                        this.loanAmount = ''
                        this.interestAmount = ''
                    }
                } else {
                    this.message = this.$i18n.locale === 'en' ? 'No Data found for this crop type!' : 'কোন তথ্য পাওয়া যায় নি'
                    this.totalQuantity = ''
                    this.loanAmount = ''
                    this.interestAmount = ''
                }
            })
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
    },
    clearData () {
        this.search = {
        cropTypeId: '0',
        cropId: '0',
        monthId: '0',
        currentPrice: '',
        productQuantity: '',
        interestRate: ''
      }
    },
    getCropList (cropTypeId = null) {
      const cropList = this.$store.state.warehouse.commodityNameList
      if (cropTypeId) {
        return cropList.filter(document => document.commodity_group_id === cropTypeId)
      }
      return cropList
    }
  }
}
</script>
